import React, { useEffect, useState } from "react";
import { connect, ConnectedProps } from "react-redux";
import InputMask from "react-input-mask";
import { useFormik } from "formik";
import * as Yup from "yup";
import { Card, Box, CardContent, Grid, Snackbar, Alert } from "@mui/material";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";
import HeaderImg from "../../../images/new-header.png";
import EasypaisaImg from "../../../images/easypaisa.png";
import CreditCardImg from "../../../images/credit-card.png";
import FawryImg from '../../../images/fawry.png'
import MWalletImg from '../../../images/mwallet.png'
import { cleanupMobile } from "&utils/cleanupMobile";
import ErrorScreen from "../error";
import WaitingModal from "../waitingModal";
import { RootState } from "&store/store";
import { fawryCheckoutActions } from "../egyptCheckout.slice";
import {servicesActions} from '&features/services/services.slice';
import styles from "../egyptCheckout.module.css";

type ReduxProps = ConnectedProps<typeof connector> & {
    sessionId: string;
  };

const PaymobCheckoutComponent = (props: ReduxProps & React.PropsWithChildren<{}>) => {
  const { sessionId, getSessionInfo, checkoutViaPaymob,getService} = props;
  const [waitingModalOpen, setWaitingModalOpen] = useState(false);

  const [serviceProvider, setServiceProvider] = useState("")
  const [transactionStatus, setTransactionStatus] = useState("waiting");
  const [errorMessage, setErrorMessage] = useState("");
  const [errorAlertOpen, setErrorAlertOpen] = useState(false)
  const [sessionInfo, setSessionInfo] = useState<any>({});
  const [error, setError] = useState("");
  const [mobileView, setMobileView] = useState(false);
  const [data, setData] = useState({
    paymentProvider: "MWallet",
    mobileNumber: "",
    email: "",
    firstName: "",
    lastName: "",
  });

  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.up("md"));

  const validation = Yup.object().shape({
    paymentProvider: Yup.string().required("Please select payment provider"),
    mobileNumber: Yup.string()
      .trim()
      .min(10)
      .required("Please provide valid mobile number"),
    firstName: Yup.string()
      .trim()
      .min(3)
      .required("Please provide valid First Name"),
    lastName: Yup.string()
      .trim()
      .min(3)
      .required("Please provide valid Last Name"),
    email: Yup.string()
      .trim()
      .email()
      .required("Please provide valid email"),
  });


  const formik = useFormik({
    enableReinitialize: true,
    initialValues: data,
    validateOnChange: true,
    validateOnBlur: true,
    onSubmit: (values) => {
      handleSubmit(values);
    },
    validationSchema: validation,
  });

  useEffect(() => {
    (async () => {
      localStorage.removeItem("service")
      if (!sessionId) return setError("Session ID not provided");
      try {
        const { payload } = await getSessionInfo(sessionId);
   
        if (payload.errors) {
          setError(payload.errors[0].message);
        }
        const {payload: service} = await getService(payload.serviceId)
       
        const cleanedMobileNumber = cleanupMobile(payload.msisdn);
        setData((prevState) => ({
          ...prevState,
          mobileNumber: cleanedMobileNumber,
        }));
        setServiceProvider(service.serviceProvider)
        setSessionInfo(payload);
      } catch (e) {
        console.log("error :", e);
      }
    })();
  }, [sessionId, getSessionInfo, getService]);

  useEffect(() => {
    const setResponsiveness = () => {
      return window.innerWidth < 1000
        ? setMobileView(true)
        : setMobileView(false);
    };

    setResponsiveness();

    window.addEventListener("resize", () => setResponsiveness());

    return () => {
      window.removeEventListener("resize", () => setResponsiveness());
    };
  }, []);

  const handlePaymentProviders = (e, formik) => {
    const val = e.target.value;
    formik.setFieldValue("paymentProvider", val);
  };

  const handleWaitingModalClose = () => {
    setWaitingModalOpen(false);
  };

  const handleMobileChange = (e, formik) => {
    const val = e.target.value.replace(/\s/g, "").trim();
    formik.setFieldValue("mobileNumber", val);
  };

  const handleKeyDown = (e) => {
    if (e.keyCode === 32) {
      e.preventDefault();
    }
  };

  const handleClose = (event: React.SyntheticEvent | Event, reason?: string) => {
    if (reason === 'clickaway') {
      return;
    }
    setErrorAlertOpen(false);
  };

  const handleSubmit = async (vals) => {
    const {
      orderId,
      serviceId,
      redirectUrl
    } = sessionInfo;

    if (vals.paymentProvider === "Credit Card") {

      const params = {
        sid: serviceId,
        paymentMethod: "CARD",
        customerMobile: vals.mobileNumber,
        firstName: vals.firstName,
        lastName: vals.lastName,
        email: vals.email,
        returnUrl: `https://wpgateway.net/checkout/mena/eg/${serviceId}/${orderId}`,
        sessionId,
        serviceId,
        orderId
      };
      setWaitingModalOpen(true);
   
      try{
        const {payload} = await checkoutViaPaymob(params)
            const redirectLink = payload.redirectLink;
            window.location.href = redirectLink
    
        setWaitingModalOpen(false);
      return;
      }catch(e){
        console.log(e)
      }
      
    }
    if(vals.paymentProvider === "MWallet"){
     
      const params = {
        sid: serviceId,
        paymentMethod: "WALLET",
        customerMobile: vals.mobileNumber,
        firstName: vals.firstName,
        lastName: vals.lastName,
        email: vals.email,
        sessionId,
        serviceId,
        orderId
      };
      setWaitingModalOpen(true);
      localStorage.setItem("service", `${serviceId}-${orderId}`)
      try{
        const {payload} = await checkoutViaPaymob(params)
            const redirectLink = payload.redirectLink;
            window.location.href = redirectLink
    
        setWaitingModalOpen(false);
      return;
      }catch(e){
        console.log(e)
      }

    }
  
  };

  const renderMobile = () => {
    return (
      <>
        {!error && (
          <Box
            sx={{
              display: "flex",
              height: "90vh",
              overflow: "auto",
              justifyContent: "center",
              alignItems: "center",
              flexDirection: "column",
            }}
          >
            <Card
              sx={{
                width: matches ? "414px" : "313px",
                overflow: "auto",
              }}
            >
              <CardContent>
                {/*Header Image*/}
                <Box
                  sx={{ width: matches ? "381px" : "297px", height: "95px" }}
                >
                  <img
                    src={HeaderImg}
                    alt="walee payment"
                    style={{
                      width: matches ? "381px" : "297px",
                      height: "95px",
                    }}
                  />
                </Box>
                {/*Instructions*/}
                <Box
                  sx={{
                    display: "flex",
                    marginY: "1rem",
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}
                >
                  <Box
                    sx={{
                      color: "#404040",
                      fontSize: "16px",
                      fontWeight: "bold",
                    }}
                  >
                    Select Payment Type
                  </Box>
                  {/*<Box
                    sx={{
                      color: "#404040",
                      fontSize: "16px",
                      fontWeight: "bold",
                    }}
                  >
                    ادائیگی کا طریقہ منتخب کریں
                  </Box>*/}
                </Box>
                {/*Payment Providers*/}

                <Grid container spacing={2}>
                  {sessionInfo.paymentMethods?.map((pack, index) => (
                    <Grid item lg={4} md={4} sm={12} key={index}>
                      <div
                        className={
                          formik.values.paymentProvider === pack
                            ? `${styles.priceBoxActive}`
                            : styles.priceBox
                        }
                      >
                        <label htmlFor={pack}>
                          <input
                            type="radio"
                            value={pack}
                            name="paymentProvider"
                            id={pack}
                            onChange={(e) => handlePaymentProviders(e, formik)}
                            hidden
                          />
                          <Box
                            sx={{
                              display: "flex",
                              cursor: "pointer",
                              flexDirection: "column",
                              gap: 2,
                              alignItems: "center",
                            }}
                          >
                            <img
                              src={
                                pack.toLowerCase() === "mwallet"
                                  ? MWalletImg
                                  : pack.toLowerCase() === "easypaisa"
                                  ? EasypaisaImg
                                  : pack.toLowerCase() === "credit card"
                                  ? CreditCardImg
                                  : FawryImg
                              }
                              alt={pack}
                              className={styles.paymentImage}
                            />
                            <Box sx={{ fontSize: "12px" }}>
                              {pack.toLowerCase() === "mwallet"
                                ? "Virtual Wallet"
                                : pack.toLowerCase() === "credit card"
                                ? "Credit Card"
                                : pack.toLowerCase() === "fawryref"
                                ? "Via Ref"
                                : pack}
                            </Box>
                          </Box>
                        </label>
                      </div>
                    </Grid>
                  ))}
                </Grid>

                {/* Mobile Number Input */}
                <Box
                  sx={{
                    display: "flex",
                    gap: 1,
                    flexDirection: "column",
                    marginTop: "2rem",
                  }}
                >
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                    }}
                  >
                    <Box
                      sx={{
                        color: "#313131",
                        fontSize: "12px",
                        fontWeight: "bold",
                      }}
                    >
                      Mobile Number
                    </Box>
                    {/*<Box
                      sx={{
                        color: "#313131",
                        fontSize: "14px",
                        fontWeight: "bold",
                      }}
                    >
                      موبائل نمبر
                    </Box>*/}
                  </Box>
                  <Box
                    sx={{
                      display: "flex",
                      padding: "1rem",
                      width: matches ? "342px" : "250px",
                      border: formik.errors.mobileNumber
                        ? "1px solid red"
                        : "1px solid #dddddd",
                      borderRadius: "7px",
                      alignItems: "center",
                      gap: 1,
                    }}
                  >
                    <Box sx={{ fontWeight: "bold" }}>+20</Box>

                    <InputMask
                      placeholder="117 123 4567"
                      mask="999 999 99999"
                      maskChar=" "
                      value={formik.values.mobileNumber}
                      onChange={(e) => handleMobileChange(e, formik)}
                    >
                      {(inputProps) => (
                        <input
                          type="tel"
                          className={styles.inputField}
                          placeholder="117 123 4567"
                          onKeyDown={(e) => handleKeyDown(e)}
                        />
                      )}
                    </InputMask>
                  </Box>
                  <Box sx={{ color: "red", fontSize: "10px" }}>
                    {formik.errors.mobileNumber}
                  </Box>
                </Box>

              {/* Name input */}
              <Box
                  sx={{
                    display: "flex",
                    gap: 1,
                    flexDirection: "column",
                    marginTop: "1rem",
                  }}
                >
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                    }}
                  >
                    <Box
                      sx={{
                        color: "#313131",
                        fontSize: "12px",
                        fontWeight: "bold",
                      }}
                    >
                      First Name
                    </Box>
                    {/*<Box
                      sx={{
                        color: "#313131",
                        fontSize: "14px",
                        fontWeight: "bold",
                      }}
                    >
                      شناختی کارڈ نمبر
                    </Box>*/}
                  </Box>
                 
                    <input
                
                    value={formik.values.firstName}
                    onChange={formik.handleChange("firstName")}
                    placeholder="First Name"
                    className={
                      formik.errors.firstName
                        ? styles.emailInputError
                        : styles.emailInput
                    }
                  />
             
                  <Box sx={{ color: "red", fontSize: "10px" }}>
                    {formik.errors.firstName}
                  </Box>
                </Box>
              {/* Last input */}
              <Box
                  sx={{
                    display: "flex",
                    gap: 1,
                    flexDirection: "column",
                    marginTop: "1rem",
                  }}
                >
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                    }}
                  >
                    <Box
                      sx={{
                        color: "#313131",
                        fontSize: "12px",
                        fontWeight: "bold",
                      }}
                    >
                      Last Name
                    </Box>
                    {/*<Box
                      sx={{
                        color: "#313131",
                        fontSize: "14px",
                        fontWeight: "bold",
                      }}
                    >
                      شناختی کارڈ نمبر
                    </Box>*/}
                  </Box>
                 
                    <input
                
                    value={formik.values.lastName}
                    onChange={formik.handleChange("lastName")}
                    placeholder="First Name"
                    className={
                      formik.errors.lastName
                        ? styles.emailInputError
                        : styles.emailInput
                    }
                  />
             
                  <Box sx={{ color: "red", fontSize: "10px" }}>
                    {formik.errors.lastName}
                  </Box>
                </Box>

                {/* Email Address */}
                <Box
                  sx={{
                    display: "flex",
                    gap: 1,
                    flexDirection: "column",
                    marginTop: "1rem",
                  }}
                >
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                    }}
                  >
                    <Box
                      sx={{
                        color: "#313131",
                        fontSize: "12px",
                        fontWeight: "bold",
                      }}
                    >
                      Email Address
                    </Box>
                    {/*<Box
                      sx={{
                        color: "#313131",
                        fontSize: "14px",
                        fontWeight: "bold",
                      }}
                    >
                      ای میل
                    </Box>*/}
                  </Box>
                  <input
                    type="email"
                    value={formik.values.email}
                    onChange={formik.handleChange("email")}
                    placeholder="john.doe@test.com"
                    className={
                      formik.errors.email
                        ? styles.emailInputError
                        : styles.emailInput
                    }
                  />
                  <Box sx={{ color: "red", fontSize: "10px" }}>
                    {formik.errors.email}
                  </Box>
                </Box>

                {/* CTA */}
                <Box sx={{ width: "100%", marginTop: "1rem" }}>
                  <button
                    type="submit"
                    className={styles.primaryCta}
                    onClick={() => formik.handleSubmit()}
                  >
                    Continue
                  </button>
                </Box>
                {/* Desclaimer */}
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    marginTop: "0.5rem",
                  }}
                >
                  <Box
                    sx={{
                      color: "#404040",
                      fontSize: "14px",
                      fontWeight: "bold",
                    }}
                  >
                    We don't have any cash refund policy
                  </Box>
                  {/*<Box
                    sx={{
                      color: "#404040",
                      fontSize: "14px",
                      fontWeight: "bold",
                    }}
                  >
                    ہماری کیش ریفندپالیسی نہیں ہے
                  </Box>*/}
                </Box>
              </CardContent>
              <WaitingModal
                open={waitingModalOpen}
                handleClose={handleWaitingModalClose}
                status={transactionStatus}
                errorMessage={errorMessage}
                matches={matches}
                 waitingIcon={false}
                 referenceNumber={null}
              />
            </Card>
          </Box>
        )}
      </>
    );
  };

  const renderDesktop = () => {
    return (
      <>
        {!error && (
          <Box
            sx={{
              display: "flex",
              height: "90vh",
              overflow: "auto",
              justifyContent: "center",
              alignItems: "center",
              flexDirection: "column",
            }}
          >
            <Card
              sx={{
                width: matches ? "414px" : "313px",
                overflow: "auto",
              }}
            >
              <CardContent>
                {/*Header Image*/}
                <Box
                  sx={{ width: matches ? "381px" : "297px", height: "95px" }}
                >
                  <img
                    src={HeaderImg}
                    alt="walee payment"
                    style={{
                      width: matches ? "381px" : "297px",
                      height: "95px",
                    }}
                  />
                </Box>
                {/*Instructions*/}
                <Box
                  sx={{
                    display: "flex",
                    marginY: "1rem",
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}
                >
                  <Box
                    sx={{
                      color: "#404040",
                      fontSize: "16px",
                      fontWeight: "bold",
                    }}
                  >
                    Select Payment Type
                  </Box>
                  {/*<Box
                    sx={{
                      color: "#404040",
                      fontSize: "16px",
                      fontWeight: "bold",
                    }}
                  >
                    ادائیگی کا طریقہ منتخب کریں
                  </Box>*/}
                </Box>
                {/*Payment Providers*/}

                <Grid container spacing={2}>
                  {sessionInfo.paymentMethods?.map((pack, index) => (
                    <Grid item lg={4} md={4} sm={12} key={index}>
                      <div
                        className={
                          formik.values.paymentProvider === pack
                            ? `${styles.priceBoxActive}`
                            : styles.priceBox
                        }
                      >
                        <label htmlFor={pack}>
                          <input
                            type="radio"
                            value={pack}
                            name="paymentProvider"
                            id={pack}
                            onChange={(e) => handlePaymentProviders(e, formik)}
                            hidden
                          />
                          <Box
                            sx={{
                              display: "flex",
                              cursor: "pointer",
                              flexDirection: "column",
                              gap: 2,
                              alignItems: "center",
                            }}
                          >
                            <img
                              src={
                                pack.toLowerCase() === "mwallet"
                                  ? MWalletImg
                                  : pack.toLowerCase() === "easypaisa"
                                  ? EasypaisaImg
                                  : pack.toLowerCase() === "credit card"
                                  ? CreditCardImg
                                  : FawryImg
                              }
                              alt={pack}
                              className={styles.paymentImage}
                            />
                            <Box sx={{ fontSize: "12px" }}>
                              {pack.toLowerCase() === "mwallet"
                                ? "Virtual Wallet"
                                : pack.toLowerCase() === "credit card"
                                ? "Credit Card"
                                : pack.toLowerCase() === "fawryref"
                                ? "Via Ref"
                                : pack}
                            </Box>
                          </Box>
                        </label>
                      </div>
                    </Grid>
                  ))}
                </Grid>

                {/* Mobile Number Input */}
                <Box
                  sx={{
                    display: "flex",
                    gap: 1,
                    flexDirection: "column",
                    marginTop: "2rem",
                  }}
                >
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                    }}
                  >
                    <Box
                      sx={{
                        color: "#313131",
                        fontSize: "12px",
                        fontWeight: "bold",
                      }}
                    >
                      Mobile Number
                    </Box>
                    {/*<Box
                      sx={{
                        color: "#313131",
                        fontSize: "14px",
                        fontWeight: "bold",
                      }}
                    >
                      موبائل نمبر
                    </Box>*/}
                  </Box>
                  <Box
                    sx={{
                      display: "flex",
                      padding: "1rem",
                      width: matches ? "342px" : "250px",
                      border: formik.errors.mobileNumber
                        ? "1px solid red"
                        : "1px solid #dddddd",
                      borderRadius: "7px",
                      alignItems: "center",
                      gap: 1,
                    }}
                  >
                    <Box sx={{ fontWeight: "bold" }}>+20</Box>

                    <InputMask
                      placeholder="117 123 4567"
                      mask="999 999 99999"
                      maskChar=" "
                      value={formik.values.mobileNumber}
                      onChange={(e) => handleMobileChange(e, formik)}
                    >
                      {(inputProps) => (
                        <input
                          type="tel"
                          className={styles.inputField}
                          placeholder="117 123 4567"
                          onKeyDown={(e) => handleKeyDown(e)}
                        />
                      )}
                    </InputMask>
                  </Box>
                  <Box sx={{ color: "red", fontSize: "10px" }}>
                    {formik.errors.mobileNumber}
                  </Box>
                </Box>

              {/* Name input */}
              <Box
                  sx={{
                    display: "flex",
                    gap: 1,
                    flexDirection: "column",
                    marginTop: "1rem",
                  }}
                >
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                    }}
                  >
                    <Box
                      sx={{
                        color: "#313131",
                        fontSize: "12px",
                        fontWeight: "bold",
                      }}
                    >
                      First Name
                    </Box>
                    
                  </Box>
                 
                    <input
                
                    value={formik.values.firstName}
                    onChange={formik.handleChange("firstName")}
                    placeholder="First Name"
                    className={
                      formik.errors.firstName
                        ? styles.emailInputError
                        : styles.emailInput
                    }
                  />
             
                  <Box sx={{ color: "red", fontSize: "10px" }}>
                    {formik.errors.firstName}
                  </Box>
              </Box>

              {/* Last Name input */}
              <Box
                  sx={{
                    display: "flex",
                    gap: 1,
                    flexDirection: "column",
                    marginTop: "1rem",
                  }}
                >
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                    }}
                  >
                    <Box
                      sx={{
                        color: "#313131",
                        fontSize: "12px",
                        fontWeight: "bold",
                      }}
                    >
                      Last Name
                    </Box>
                    
                  </Box>
                 
                    <input
                
                    value={formik.values.lastName}
                    onChange={formik.handleChange("lastName")}
                    placeholder="Last Name"
                    className={
                      formik.errors.lastName
                        ? styles.emailInputError
                        : styles.emailInput
                    }
                  />
             
                  <Box sx={{ color: "red", fontSize: "10px" }}>
                    {formik.errors.lastName}
                  </Box>
              </Box>

                {/* Email Address */}
                <Box
                  sx={{
                    display: "flex",
                    gap: 1,
                    flexDirection: "column",
                    marginTop: "1rem",
                  }}
                >
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                    }}
                  >
                    <Box
                      sx={{
                        color: "#313131",
                        fontSize: "12px",
                        fontWeight: "bold",
                      }}
                    >
                      Email Address
                    </Box>
                    {/*<Box
                      sx={{
                        color: "#313131",
                        fontSize: "14px",
                        fontWeight: "bold",
                      }}
                    >
                      ای میل
                    </Box>*/}
                  </Box>
                  <input
                    type="email"
                    value={formik.values.email}
                    onChange={formik.handleChange("email")}
                    placeholder="john.doe@test.com"
                    className={
                      formik.errors.email
                        ? styles.emailInputError
                        : styles.emailInput
                    }
                  />
                  <Box sx={{ color: "red", fontSize: "10px" }}>
                    {formik.errors.email}
                  </Box>
                </Box>     
                {/* CTA */}
                <Box sx={{ width: "100%", marginTop: "1rem" }}>
                  <button
                    type="submit"
                    className={styles.primaryCta}
                    onClick={() => formik.handleSubmit()}
                  >
                    Continue
                  </button>
                </Box>
                {/* Desclaimer */}
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    marginTop: "0.5rem",
                  }}
                >
                  <Box
                    sx={{
                      color: "#404040",
                      fontSize: "14px",
                      fontWeight: "bold",
                    }}
                  >
                    We don't have any cash refund policy
                  </Box>
                  {/*<Box
                    sx={{
                      color: "#404040",
                      fontSize: "14px",
                      fontWeight: "bold",
                    }}
                  >
                    ہماری کیش ریفندپالیسی نہیں ہے
                  </Box>*/}
                </Box>
              </CardContent>
              <WaitingModal
                open={waitingModalOpen}
                handleClose={handleWaitingModalClose}
                status={transactionStatus}
                errorMessage={errorMessage}
                matches={matches}
                 waitingIcon={false}
                 referenceNumber={null}
              />
              
            </Card>
          </Box>
        )}
      </>
    );
  };

  return (
    <>
    {error && <ErrorScreen error={error} matches={matches} />}
    {mobileView ? renderMobile() : renderDesktop()}
    <Snackbar
        open={errorAlertOpen}
        autoHideDuration={6000}
        onClose={handleClose}
      >
    <Alert onClose={handleClose} severity="error" sx={{ width: '100%' }}>
        {errorMessage}
    </Alert>
    </Snackbar>
  </>
  )
};

/**
 * Maps state variables from redux store to props of currect component
 * @param state
 */
const mapStateToProps = (state: RootState) => ({
     
});

/**
 * Maps actions from slices to props
 */
const mapDispatchToProps = {
  getSessionInfo: fawryCheckoutActions.getSessionInfo,
  checkoutViaPaymob: fawryCheckoutActions.checkoutViaPaymob,
  checkStatus: fawryCheckoutActions.checkStatus,
  updateStatus: fawryCheckoutActions.updateStatus,
  getService: servicesActions.getServiceBySid,
  
};

const connector = connect(mapStateToProps, mapDispatchToProps);
const PaymobCheckoutComponentRedux = connector(PaymobCheckoutComponent);

export { PaymobCheckoutComponentRedux as PaymobCheckoutComponent };
